<template>
  <div
    class="vinculacionexterna full-width row wrap justify-center items-center content-center q-pa-none q-ma-none q-px-sm"
    style="min-height: calc(100vh - 50px)"
  >
    <q-card
      class="full-width row wrap justify-center items-center content-start q-pa-none q-ma-none"
      style="min-height: calc(100vh - 60px)"
    >
      <div class="full-width row wrap" style="">
        <div
          class="col-12 justify-center items-center content-center q-ma-none q-pa-none"
          style=""
        >
          <interstellar-text
            id="vinculacionexterna"
            class="text-h3 q-ma-none q-pa-none"
          >
            Vinculación Externa
          </interstellar-text>
        </div>
        <div
          class="full-width row wrap justify-center items-center content-center"
        >
          <q-tabs
            v-model="tab"
            dense
            class="col-12 bg-primary text-white shadow-2 rounded-borders"
            active-color="white"
            indicator-color="white"
            align="center"
            narrow-indicator
            inline-label
          >
            <q-tab name="nuevas" label="Nuevas" class="text-capitalize">
              <q-badge
                v-if="badgeNuevas > 0"
                style="background: rgba(200, 200, 200, 0.7)"
                floating
              >
                {{ 1 }}
              </q-badge>
            </q-tab>
            <q-tab
              name="seguimiento"
              label="Seguimiento"
              class="text-capitalize"
            >
              <q-badge
                v-if="badgeSeguimiento > 0"
                style="background: rgba(200, 200, 200, 0.7)"
                floating
              >
                {{ 1 }}
              </q-badge>
            </q-tab>
            <q-tab
              name="canalizadas"
              label="Canalizadas"
              class="text-capitalize"
            >
              <q-badge
                v-if="badgeCanalizadas > 0"
                style="background: rgba(200, 200, 200, 0.7)"
                floating
              >
                {{ 1 }}
              </q-badge>
            </q-tab>
          </q-tabs>
        </div>
        <div
          class="full-width row wrap justify-center items-center content-center"
        >
          <q-tab-panels v-model="tab" animated class="col-12">
            <q-tab-panel name="nuevas" class="q-ma-none q-pa-none q-pt-sm">
              <solicitudes-nuevas title="Solicitudes Nuevas">
              </solicitudes-nuevas>
            </q-tab-panel>
            <q-tab-panel name="seguimiento" class="q-ma-none q-pa-none q-pt-sm">
              <solicitudes-seguimiento
                title="Solicitudes Seguimiento"
              ></solicitudes-seguimiento>
            </q-tab-panel>
            <q-tab-panel name="canalizadas" class="q-ma-none q-pa-none q-pt-sm">
              <solicitudes-canalizadas
                title="Solicitudes Canalizadas"
              ></solicitudes-canalizadas>
            </q-tab-panel>
          </q-tab-panels>
        </div>
      </div>
    </q-card>
  </div>
</template>

<style></style>

<script>
import { defineAsyncComponent, ref, onMounted } from "vue";

export default {
  name: "vinculacionexterna",
  components: {
    InterstellarText: defineAsyncComponent(() =>
      import("@/components/common/InterstellarText.vue")
    ),
    SolicitudesNuevas: defineAsyncComponent(() =>
      import("@/views/vinculacionexterna/components/SolicitudesNuevas.vue")
    ),
    SolicitudesSeguimiento: defineAsyncComponent(() =>
      import("@/views/vinculacionexterna/components/SolicitudesSeguimiento.vue")
    ),
    SolicitudesCanalizadas: defineAsyncComponent(() =>
      import("@/views/vinculacionexterna/components/SolicitudesCanalizadas.vue")
    ),
    // AnimalApi: defineAsyncComponent(() =>
    //   import("@/components/examples/AnimalApi.vue")
    // ),
  },

  setup() {
    console.log("VinculacioExterna ready");

    const tab = ref("seguimiento");
    const badgeNuevas = ref(0);
    const badgeSeguimiento = ref(0);
    const badgeCanalizadas = ref(0);
    const badgeConcluidas = ref(0);

    onMounted(() => {});
    return {
      tab,
      badgeNuevas,
      badgeSeguimiento,
      badgeCanalizadas,
      badgeConcluidas,
    };
  },
};
</script>

<style>
.my-sticky-virtscroll-table {
  /* height or max-height is important */
  height: 410px;
  /* this will be the loading indicator */
}
.my-sticky-virtscroll-table .q-table__top,
.my-sticky-virtscroll-table .q-table__bottom,
.my-sticky-virtscroll-table thead tr:first-child th {
  background-color: #fff;
}
.my-sticky-virtscroll-table thead tr th {
  position: sticky;
  z-index: 1;
}
.my-sticky-virtscroll-table thead tr:last-child th {
  /* height of all previous header rows */
  top: 48px;
}
.my-sticky-virtscroll-table thead tr:first-child th {
  top: 0;
}
</style>
