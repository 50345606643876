import { render } from "./VinculacionExterna.vue?vue&type=template&id=e3fd2302"
import script from "./VinculacionExterna.vue?vue&type=script&lang=js"
export * from "./VinculacionExterna.vue?vue&type=script&lang=js"

import "./VinculacionExterna.vue?vue&type=style&index=0&id=e3fd2302&lang=css"
script.render = render

export default script
import QCard from 'quasar/src/components/card/QCard.js';
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QTab from 'quasar/src/components/tabs/QTab.js';
import QBadge from 'quasar/src/components/badge/QBadge.js';
import QTabPanels from 'quasar/src/components/tab-panels/QTabPanels.js';
import QTabPanel from 'quasar/src/components/tab-panels/QTabPanel.js';
import QTable from 'quasar/src/components/table/QTable.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCard,QTabs,QTab,QBadge,QTabPanels,QTabPanel,QTable});
